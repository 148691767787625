<template>
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="2"
          >
            <label for="begin-date">Início em</label>
            <b-form-datepicker
              id="begin-date"
              v-model="beginDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <label for="end-date">Fim em</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <label for="workplace-id">Unidades</label>
            <v-select
              id="workplace-id"
              v-model="workplaceId"
              name="workplace-id"
              :options="workplaceOptions"
              label="label"
              item-value="value"
              item-text="label"
            >
              <span slot="no-options">Nenhum local encontrado.</span>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex w-100">
              <div class="flex-grow-1">
                <label for="physician-user-id">Médicos</label>
                <v-select
                  id="physician-user-id"
                  v-model="physicianUserId"
                  name="physician-user-id"
                  :options="physicianUserOptions"
                  label="label"
                  item-value="value"
                  item-text="label"
                >
                  <span slot="no-options">Nenhum médico encontrado.</span>
                </v-select>
              </div>
              <div class="flex-grow-1 ml-2">
                <label for="anteciped-user-id">Antecipado</label>
                <v-select
                  id="anteciped-id"
                  v-model="anteciped"
                  name="physician-id"
                  :options="antecipedOptions"
                  label="label"
                  item-value="value"
                  item-text="label"
                />
              </div>
              <div class="pt-2 ml-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon mx-1"
                  @click="loadReport"
                >
                  Buscar
                  <feather-icon
                    icon="SearchIcon"
                    size="16"
                  />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  :disabled="reports.length === 0"
                  @click="downloadReport"
                >
                  Exportar
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <!-- Workplaces -->
            <div v-if="reports.length === 0" class="m-5">
              <h4 class="text-center w-100">
                Não há registros
              </h4>
            </div>
            <app-collapse accordion type="border">
              <app-collapse-item v-for="report in reports" :key="report.name" :title="report.physician_name || ''">
                <template v-slot:header>
                  <div class="d-flex justify-content-between w-100">
                    <div class="d-flex flex-column">
                      <h5 class="text-bold mb-0">
                        {{ report.physician_name || '-' }}
                      </h5>
                      <h6 class="text-muted mb-0">
                        {{ report.workplace_name }}
                      </h6>
                    </div>
                    <div class="d-flex flex-column ml-1 flex-grow-1">
                      <h6 class="text-bold mb-0">
                        <span class="text-muted">Início</span> {{ formatDate(report.start_date) }}
                      </h6>
                      <h6 class="text-bold mb-0">
                        <span class="text-muted">Fim</span> {{ formatDate(report.end_date) }}
                      </h6>
                    </div>
                    <div class="d-flex flex-column ml-1 flex-grow-1">
                      <h6 class="text-bold mb-0">
                        <span class="text-muted">Tipo</span> {{ report.person_type || 'PF' }}
                      </h6>
                    </div>
                    <div class="text-right">
                      <div>
                        <span class="text-muted">Valor Pago</span>
                        <span class="collapse-title"> {{ currency(report.amount_paid) }}</span>
                      </div>
                      <div>
                        <span class="text-muted">Status</span>
                        <span class="collapse-title"> {{ report.anteciped }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <app-collapse accordion>
                  <div class="d-flex">
                    <div class="mr-1">
                      <label>CPF</label>
                      <h6 class="text-bold">
                        {{ report.physician_cpf || '-' }}
                      </h6>
                    </div>
                    <div class="mr-1">
                      <label>CRM</label>
                      <h6 class="text-bold">
                        {{ report.physician_crm || '-' }}
                      </h6>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="mr-1">
                      <label>Período</label>
                      <h6 class="text-bold">
                        {{ report.period }}
                      </h6>
                    </div>
                    <div class="mr-1">
                      <label>Tipo</label>
                      <h6 class="text-bold">
                        {{ report.duty_type }}
                      </h6>
                    </div>
                    <div v-if="report.physician_company_cnpj" class="mr-1">
                      <label>Empresa</label>
                      <h6 class="text-bold">
                        {{ report.physician_company_name }}
                      </h6>
                    </div>
                    <div v-if="report.physician_company_cnpj" class="mr-1">
                      <label>CNPJ</label>
                      <h6 class="text-bold">
                        {{ formatCnpj(report.physician_company_cnpj) }}
                      </h6>
                    </div>
                  </div>
                </app-collapse>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  format, parseISO, endOfMonth, startOfMonth,
} from 'date-fns'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import {
  ref, onUnmounted,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { formatCnpj, formatCpf } from '@core/utils/utils'
import reportStoreModule from '../reportStoreModule'
import useReport from './useReport'

export default {
  directives: {
    Ripple,
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    vSelect,
    BButton,
  },
  methods: { formatCnpj },
  setup() {
    const REPORTS_STORE_MODULE_NAME = 'app-reports'
    // Register module
    if (!store.hasModule(REPORTS_STORE_MODULE_NAME)) store.registerModule(REPORTS_STORE_MODULE_NAME, reportStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTS_STORE_MODULE_NAME)) store.unregisterModule(REPORTS_STORE_MODULE_NAME)
    })

    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    const currency = value => formatter.format(value / 100)
    const formatDate = value => format(parseISO(value), 'dd/MM/yyyy HH:mm')
    const reports = ref([])
    const beginDate = ref(format(startOfMonth(new Date()), 'yyyy-MM-dd'))
    const endDate = ref(format(endOfMonth(new Date()), 'yyyy-MM-dd'))
    const physicianUserId = ref('')
    const workplaceId = ref('')
    const anteciped = ref('')
    const physicianUserOptions = ref([])
    const workplaceOptions = ref([])
    const antecipedOptions = ref([
      {
        value: 1,
        label: 'Antecipado',
      },
      {
        value: 0,
        label: 'Não Antecipado',
      },
    ])
    const workplace = ref({
      address: {},
      duties: {
        6: '',
        12: '',
        24: '',
      },
    })

    const getReportParams = () => ({
      begin: beginDate.value,
      end: endDate.value,
      workplace: workplaceId.value ? workplaceId.value.value : '',
      user: physicianUserId.value ? physicianUserId.value.value : '',
      anteciped: anteciped.value ? anteciped.value.value : '',
    })

    const loadReport = () => {
      store.dispatch('app-reports/fetchReportAccountability', getReportParams())
        .then(response => {
          reports.value = response.data.data.map(report => {
            const cpf = report.physician_cpf ? formatCpf(report.physician_cpf, true) : ''
            return {
              ...report,
              physician_cpf: cpf,
            }
          })
        })
    }
    loadReport()

    const downloadReport = () => {
      const params = getReportParams()
      store.dispatch('app-reports/fetchReportAccountabilityExport', params)
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `PrestaçãoContas_${params.begin}_${params.end}.xlsx`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }

    store.dispatch('app-reports/fetchPhysicians', { perPage: 99999999 })
      .then(response => {
        physicianUserOptions.value = response.data.data.map(physician => ({
          key: physician.id,
          value: physician.user_id,
          label: `${physician.name} - ${formatCpf(physician.cpf, true)}`,
        }))
      })

    store.dispatch('app-reports/fetchWorkplaces', { perPage: 99999999 })
      .then(response => {
        workplaceOptions.value = response.data.data.map(work => ({
          value: work.id,
          label: `${work.name}`,
        }))
      })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const {
      tableColumns,
      perPage,
      currentPage,
      totalWorkplaces,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refWorkplaceListTable,

      refetchData,
      fetchWorkplaces,

      updateWorkplace,
      searchQuery,
      sortBy,
      phoneMask,
    } = useReport()

    return {
      currency,
      formatDate,
      reports,

      updateWorkplace,
      searchQuery,
      fetchWorkplaces,
      perfectScrollbarSettings,
      sortBy,
      loadReport,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      beginDate,
      endDate,
      physicianUserId,
      workplaceId,
      tableColumns,
      perPage,
      currentPage,
      totalWorkplaces,
      dataMeta,
      perPageOptions,
      isSortDirDesc,
      refWorkplaceListTable,
      refetchData,
      workplace,
      phoneMask,
      physicianUserOptions,
      workplaceOptions,
      downloadReport,
      antecipedOptions,
      anteciped,
    }
  },
}
</script>

<style lang="scss" scoped>
.text-muted {
  color: #898993!important;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.panel-physician {
  background-color: rgb(248, 248, 248);
}

label {
  margin-top: 0.2857rem!important;
}

[dir=ltr] [class*=collapse-] .card .card-header {
  padding: .5rem 2.8rem .5rem .5rem;
}

.text-right, .collapse-title {
  font-size: 13px!important;
}

</style>
